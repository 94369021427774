import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../layouts'
import './index.css'
import CystalPark from '../images/fyling-horse.jpg'
import CommonHero from '../components/commonhero/commonhero.js'

class IndexPage extends React.Component {
  render() {
    return (
      <Layout head={{ title: 'Manitou Springs' }}>
        <CommonHero
          pageTitle="Manitou Springs"
          custombackground="https://res.cloudinary.com/brian-boals/image/upload/v1562724754/brianboals/manitou-springs.jpg"
        />
        <main className="bg-white p-relative">
          <div className="container py-5">
            <p className="text-center">
              Fun and funky with lots of shops, restaurants and festivals. Older
              community with historic housing.
            </p>
          </div>
          <section className="py-5">
            <div className="container">
              <div className="intro text-center mb-5">
                <h2>Neighborhoods</h2>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${CystalPark})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-crystalpark.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Crystal Park"
                        >
                          Crystal Park
                        </a>
                      </h4>
                      <p>
                        Nestled above historic Manitou Springs, this community
                        boasts true Colorado living with breathtaking views and
                        rugged mountain terrain.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default IndexPage
